import { isMobile } from "react-device-detect";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/utils";
import CollabCurrency from "../asset/logos/CollabCurrency.png";
import Patron from "../asset/logos/Patron.png";
import PolygonStudios from "../asset/logos/PolygonStudios.png";
import Lemniscap from "../asset/logos/Lemniscap.png";
import DWeb3 from "../asset/logos/DWeb3.png";
import SamsungNext from "../asset/logos/SamsungNext.png";
import Verse2 from "../asset/logos/Verse2.png";
import Planetarium from "../asset/logos/planetarium_logo.png";
import {
  BACKGROUND_GRAY,
  SECTION_MAX_WIDTH,
  SECTION_PADDING,
} from "../utils/constants";

type StyleProps = {
  iconWidth: number;
};

const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    cards: {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-around",
      maxWidth: SECTION_MAX_WIDTH,
      margin: "0 auto",
      padding: isMobile ? "2em 2em 0em 2em" : "0 5em",
    },
    title: {
      display: "flex",
      justifyContent: "center",
      fontFamily: "'Roboto', sans-serif;",
      color: "#FFFFFF",
      fontSize: "48px",
      fontWeight: 900,
    },
    icon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: isMobile ? "1em 1em" : "1em 1em 5em 1em",
      flexWrap: "wrap",
      width: ({ iconWidth }) => iconWidth,
    },
    container: {
      padding: SECTION_PADDING,
      background: BACKGROUND_GRAY,
      minHeight: isMobile ? undefined : "50vh",
    },
    footer: {
      fontSize: isMobile ? "0.8em" : "1rem",
      fontStyle: "italic",
      color: "#fff",
      display: "flex",
      justifyContent: "center",
      paddingBottom: "1em",
      textAlign: "center",
      fontFamily: "'Roboto', sans-serif;",
      margin: "0 auto",
      maxWidth: SECTION_MAX_WIDTH,
    },
  })
);

export default function Partners() {
  const width = isMobile ? 200 : 300;
  const classes = useStyles({ iconWidth: width });

  return (
    <div className={classes.container}>
      <div className={classes.title}>Investors</div>
      <div className={classes.footer}>Believers in Anykraft</div>

      <div className={classes.cards}>
        <a
          className={classes.icon}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.collabcurrency.com"
        >
          <img src={CollabCurrency} width={width} />
        </a>
        <a
          className={classes.icon}
          target="_blank"
          rel="noopener noreferrer"
          href="https://patron.xyz/"
        >
          <img src={Patron} width={width} />
        </a>
        <a
          className={classes.icon}
          target="_blank"
          rel="noopener noreferrer"
          href="https://polygonstudios.com/"
        >
          <img src={PolygonStudios} width={width} />
        </a>
        <a
          className={classes.icon}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.samsungnext.com/"
        >
          <img src={SamsungNext} width={Math.min(width, 200)} />
        </a>
        <a
          className={classes.icon}
          target="_blank"
          rel="noopener noreferrer"
          href="https://lemniscap.com/"
        >
          <img
            src={Lemniscap}
            width={width}
            style={{
              marginTop: isMobile ? 0 : "1.5em",
              padding: isMobile ? "1em 0" : 0,
            }}
          />
        </a>
        <a
          className={classes.icon}
          target="_blank"
          rel="noopener noreferrer"
          href="https://planetariumhq.com/"
        >
          <img src={Planetarium} width={width} />
        </a>
        <a
          className={classes.icon}
          target="_blank"
          rel="noopener noreferrer"
          href="https://dweb3.io/"
        >
          <img src={DWeb3} width={width} />
        </a>
        <a
          className={classes.icon}
          target="_blank"
          rel="noopener noreferrer"
          href="https://verse2.io/"
        >
          <img src={Verse2} width={width} />
        </a>
      </div>
    </div>
  );
}
