import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/utils";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ChiProfile from "../asset/decorations/ChiProfile.png";
import LukeProfile from "../asset/decorations/LukeProfile.png";
import PaulProfile from "../asset/decorations/PaulProfile.jpeg";
import JunProfile from "../asset/decorations/JunProfile.png";

import KyuHyunProfile from "../asset/decorations/KyuHyunProfile.jpeg";
import EduardoProfile from "../asset/decorations/EduardoProfile.jpeg";

import ArtStationLogoColor from "../asset/icons/ArtstationIcon.png";
import GooglePlayLogoGrey from "../asset/icons/GooglePlayLogoGrey2.png";
import LinkedInLogoColor from "../asset/icons/LinkedInLogoColor.png";
import SoowanProfile from "../asset/decorations/SoowanProfile2.jpg";
import { SECTION_MAX_WIDTH, SECTION_PADDING } from "../utils/constants";

const ICON_LARGE = {
  margin: "1em auto",
  width: 200,
  height: 200,
  boxShadow: "0px 0px 20px rgb(192,192,192, 0.5)",
};
const ICON_STYLE = {
  marginTop: "auto",
  marginRight: "auto",
};

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    content: {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "center",
      paddingBottom: "0em 5em 3em 5em",
      fontFamily: "'Roboto', sans-serif;",
      margin: "0 auto",
      maxWidth: SECTION_MAX_WIDTH,
    },
    title: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      fontFamily: "'Roboto', sans-serif;",
      color: "#000000",
      fontSize: "48px",
      fontWeight: 900,
      margin: "0 auto",
    },
    member: {
      display: "flex",
      flexDirection: "column",
      margin: isMobile ? "2em 0em 0em 0em" : "2em 1em 0em 1em",
      fontFamily: "'Roboto', sans-serif;",
      color: "#000000",
      fontSize: "1.9rem",
      width: 300,
      borderRadius: 16,
      border: "1px solid #FFFFFF",
      boxShadow: "0px 0px 20px rgb(192,192,192, 0.5)",
      padding: "1em",
      transition: "all 0.5s ease",
      "&:hover": {
        transform: "scale(1.04)",
        transition: "all 0.5s ease",
      },
    },
    memberDescription: {
      paddingTop: "1em",
      fontFamily: "'Roboto', sans-serif;",
      color: "#6A6A6A",
      fontSize: "1.2rem",
      margin: "0 auto",
      lineHeight: "1.5em",
    },
    memberSubtitle: {
      textAlign: "center",
      fontFamily: "'Roboto', sans-serif;",
      fontWeight: "lighter",
      color: "#6A6A6A",
      fontSize: "1.2rem",
      margin: "0 auto",
      paddingTop: "0.3em",
    },

    iconRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      color: "#00A1F7",
    },
    container: {
      padding: SECTION_PADDING,
      minHeight: isMobile ? undefined : "50vh",
      background: "#FFFFFF",
    },
    footer: {
      fontSize: isMobile ? "0.8em" : "1rem",
      fontStyle: "italic",
      color: "#000000",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      fontFamily: "'Roboto', sans-serif;",
      margin: "0 auto",
      paddingTop: "10px",
      maxWidth: SECTION_MAX_WIDTH,
    },
  })
);

export default function Team() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>Founding Team</div>
      <div className={classes.footer}>Built by the Experts</div>
      <div className={classes.content}>
        <div className={classes.member}>
          <Avatar alt="luke" src={LukeProfile} sx={ICON_LARGE} />
          <div className={classes.iconRow}>Luke</div>
          <div className={classes.memberSubtitle}>CEO</div>
          <div className={classes.memberSubtitle}>Ex-Bloomberg</div>
          <div className={classes.memberDescription}>
            He firmly supports games that are Free-to-Play, UGC-centered,
            Competitive, with a touch of digital collectibles and creator
            economy.
          </div>
          <div className={classes.memberSubtitle}>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component={Link}
              to={{
                pathname: "https://www.linkedin.com/in/luke-ahn-32b00089/",
              }}
              target="_blank"
              sx={ICON_STYLE}
            >
              <img src={LinkedInLogoColor} width={28} alt="LinkedInLogo-logo" />
            </IconButton>
          </div>
        </div>
        <div className={classes.member}>
          <Avatar alt="chi" src={ChiProfile} sx={ICON_LARGE} />
          <div className={classes.iconRow}>Chi Hyun</div>
          <div className={classes.memberSubtitle}>CTO</div>
          <div className={classes.memberSubtitle}>Ex-Pokémon Go</div>
          <div className={classes.memberDescription}>
            Chi Hyun is an expert with a strong commitment to craftsmanship.
            Drawing on his previous experiences at Niantic (Pokémon Go), Amazon,
            and Nexon, Chi Hyun possesses unparalleled skills in game
            engineering.
          </div>
          <div className={classes.memberSubtitle}>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component={Link}
              to={{
                pathname: "https://www.linkedin.com/in/chi-hyun-lim-240081a1/",
              }}
              target="_blank"
              sx={ICON_STYLE}
            >
              <img src={LinkedInLogoColor} width={28} alt="LinkedInLogo-logo" />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}
